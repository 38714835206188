

// icons
import { mail, person, chatbubbleEllipsesOutline, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonRow, IonButtons, IonButton, IonIcon, IonBackButton,
        IonItem, IonLabel, IonList, IonListHeader,
        IonInput, IonTextarea,
        loadingController, } from '@ionic/vue';
import ProductCard from "@/components/product/ProductCard.vue";

import { computed, ref } from 'vue';

// services
import CommonService from '@/services/CommonService';

import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { utils } from '@/composables/utils';

export default {
  name: 'FeedbackPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
                IonGrid, IonRow, IonButtons, IonButton, IonIcon, IonBackButton,
                IonItem, IonLabel, IonList, IonListHeader,
                IonInput, IonTextarea, ProductCard, },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const { presentToast } = utils();
    const route = useRoute();
    const { productId } = route.params;

    const user = computed(() => store.state.user);
    const name = ref(user.value.name || "");
    const email = ref(user.value.email || "");
    const feedback = ref("");
    const product = computed(() => store.getters.getProductById(productId));

    // methods
    const submitFeedback = async () => {
      const loading = await loadingController.create({});
      await loading.present();
      const res = await CommonService.createNewFeedback(name.value, email.value, feedback.value, product.value);
      loading.dismiss();
      feedback.value = "";
      presentToast( t('FeedbackPage.feedbackSubmitted'), 5000, 'top' );
    }
    
    return {
      // icons
      mail, person, chatbubbleEllipsesOutline,
      
      // variables
      name, email, feedback, product,

      // methods
      t, submitFeedback,
    }
  },
}
